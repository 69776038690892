import React from "react";

type EventCardProps = {
  title: string;
  date: string;
  image: string;
};

const EventCard: React.FC<EventCardProps> = ({ title, image, date }) => {
  return (
    <div className="rounded-xl h-[300px] relative">
      <div className="h-full w-full z-10">
        <img src={image} className="h-full w-full object-cover rounded-xl" />
      </div>
      <div className="w-full p-5 rounded-xl bg-mca-grey-3 z-20 absolute left-0 bottom-0 flex flex-col gap-y-3 -mb-[5px]">
        <div className="text-xl font-bold">{title}</div>
        <div className="flex justify-between items-center">
          <div className="flex items-center text-mca-grey-2 gap-x-2 font-semibold">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-mca-grey-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>{date.replace(/\s/g, "/")}</span>
          </div>
          <div>
            <div className="block bg-mca-green px-3 py-1 text-sm uppercase text-mca-off-white rounded-full font-bold cursor-pointer">
              RSVP
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
