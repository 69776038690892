import React, { useEffect, useRef } from "react";
import BlogPost from "../BlogPost";
import EventCard from "../Events/EventCard";

import CarouselPlaceholder from "../../images/carousel-placeholder.png";
import featuredInitiatives from "../../data/landing/featured-initiatives";
import recentUpdates from "../../data/landing/recent-updates";
import { StaticImage } from "gatsby-plugin-image";

const Header: React.FC = () => {
  const row1 = useRef<HTMLDivElement>(null);
  const row2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (row1.current) row1.current.scrollLeft = 800;
    if (row2.current) row2.current.scrollLeft = 500;
  });

  return (
    <div className="w-full my-5 mb-20">
      <div className="px-10">
        <div className="text-2xl sm:text-5xl font-extrabold text-mca-grey">
          Sample <span className="text-mca-green">Initiative</span>
        </div>
        <div className="text-mca-grey-2 font-bold max-w-sm w-full my-3 text-lg sm:text-2xl">
          Short form description of the initative, in one line.
        </div>
      </div>
      <div className="w-full relative my-5 h-[53vh] flex flex-col justify-between">
        <div
          className="overflow-hidden w-full text-nowrap h-[25vh] flex gap-x-5 sm:gap-x-[3vh] text-3xl sm:text-6xl font-extrabold"
          ref={row1}
        >
          {Array.from({ length: 30 }).map((_, i) => (
            <img
              src={CarouselPlaceholder}
              className="h-full w-auto object-cover rounded-xl"
              key={i}
            />
          ))}
        </div>
        <div
          className="overflow-hidden w-full text-nowrap h-[25vh] flex gap-x-5 sm:gap-x-[3vh] text-3xl sm:text-6xl font-extrabold"
          ref={row2}
        >
          {Array.from({ length: 30 }).map((_, i) => (
            <img
              src={CarouselPlaceholder}
              className="h-full w-auto object-cover rounded-xl"
              key={i}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const Description: React.FC = () => {
  return (
    <div className="my-16 sm:my-32 relative max-w-screen-lg w-full mx-auto px-5">
      <div className="absolute w-full h-full top-0 left-0 flex items-center justify-center z-10">
        <StaticImage
          src="../../images/blobs/blob-1.png"
          alt="blob"
          placeholder="blurred"
          height={300}
        />
      </div>

      <div className="relative z-20">
        <div
          className="text-lg sm:text-4xl text-mca-grey font-bold leading-relaxed"
          style={{ textIndent: "2.3em" }}
        >
          Lorem ipsum dolor sit amet,{" "}
          <span className="text-mca-green">consectetur adipiscing elit</span>,
          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
          <span className="text-mca-green">Ut enim ad minim veniam</span>, quis
          nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat.
        </div>
      </div>
    </div>
  );
};

const RelatedEvents: React.FC = () => {
  return (
    <div className="my-20 sm:my-16 max-w-screen-xl w-full mx-auto px-5">
      <div className="text-xl sm:text-2xl uppercase text-mca-grey font-bold">
        Related Events
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-10 my-5">
        {Array.from({ length: 3 }).map((_, i) => (
          <EventCard
            key={i}
            title="Misinformation Conference, 2021"
            date="13th January, 2022"
            image={CarouselPlaceholder}
          />
        ))}
      </div>
    </div>
  );
};

const RelatedPosts: React.FC = () => {
  return (
    <div className="my-16 sm:my-20 relative max-w-screen-xl w-full mx-auto px-5">
      <div className="relative z-20">
        <div className="text-xl sm:text-2xl uppercase text-mca-grey font-bold">
          Related Posts
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-3 sm:gap-x-10 gap-y-10 my-5">
          {recentUpdates.slice(0, 3).map((post, i) => (
            <BlogPost post={post} key={i} />
          ))}
        </div>
      </div>
    </div>
  );
};

const OtherInitiatives: React.FC = () => {
  return (
    <div className="my-20 max-w-screen-xl w-full mx-auto px-5">
      <div className="text-center text-3xl sm:text-6xl text-mca-grey font-extrabold">
        Other <span className="text-mca-green">Initiatives</span>
      </div>
      <div className="grid sm:grid-cols-3 gap-y-10 sm:gap-x-10 my-5 sm:my-10">
        {featuredInitiatives.map(({ image, title, description }, i) => (
          <div className="flex flex-col gap-y-4">
            <img
              src={image.src}
              alt={title}
              className={`w-full h-72 object-cover ${
                i === 0 ? "sm:rounded-tl-[90px]" : ""
              } ${i === 2 ? "sm:rounded-br-[90px]" : ""}`}
            />
            <div className="flex flex-col gap-y-1">
              <div className="text-2xl font-bold text-mca-grey">{title}</div>
              <div className="text-mca-grey-2" style={{ fontWeight: 600 }}>
                {description}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { Header, Description, RelatedEvents, RelatedPosts, OtherInitiatives };
