const featuredInitiatives = [
  {
    image: {
      src: "https://picsum.photos/500/300?asd",
      width: 500,
      height: 300,
    },
    title: "Public Policy",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    image: {
      src: "https://picsum.photos/500/300?dads",
      width: 500,
      height: 300,
    },
    title: "Public Policy",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    image: {
      src: "https://picsum.photos/500/300?asjdaks",
      width: 500,
      height: 300,
    },
    title: "Public Policy",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

export default featuredInitiatives;
