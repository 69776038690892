import React from "react";
import Footer from "../components/Footer";
import {
  Header,
  Description,
  RelatedEvents,
  RelatedPosts,
  OtherInitiatives,
} from "../components/Initiative";
import Navbar from "../components/Navbar";

const InitiativePage = () => {
  return (
    <div>
      <Navbar />
      <Header />
      <Description />
      {/* <RelatedEvents /> */}
      {/* <RelatedPosts /> */}
      <OtherInitiatives />
      <Footer />
    </div>
  );
};

export default InitiativePage;
